import React from "react";
import { css } from "@emotion/core";
import { Layout } from "../components/Layout";
import { Head } from "../components/Head";
import { font, mediaQuery, smartPhone } from "../constants/styles";

const wrapper = css`
  width: 80%;
  margin: 0 auto;
  font-size: ${font.textSize};
  line-height: 2;
  ${mediaQuery[smartPhone]} {
    width: 90%;
  }
`;

export default function NotFound() {
  const title = "エラー";

  return (
    <Layout>
      <Head props={title} />
      <div css={wrapper}>
        <div>アクセスしたページは存在しません。</div>
      </div>
    </Layout>
  );
}
